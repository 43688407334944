// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reminder-statements {
  max-width: 600px;
}

.reminder-item {
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.reminder-item:hover {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.reminder-text-list {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
}

.tag-style-list {
  background-color: #c8e6c9;
  color: #2e7d32;
  padding: 2px 8px;
  border-radius: 4px;
}

.number-highlight-list {
  background-color: #2e7d32;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/App/components/Loan/admin/LoanReminders/ReminderSettings.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".reminder-statements {\n  max-width: 600px;\n}\n\n.reminder-item {\n  background-color: #f8f9fa;\n  border-radius: 8px;\n  border: 1px solid #dee2e6;\n  transition: all 0.2s ease;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n\n.reminder-item:hover {\n  background-color: #fff;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}\n\n.reminder-text-list {\n  font-size: 1.1rem;\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  gap: 0.5rem;\n}\n\n.tag-style-list {\n  background-color: #c8e6c9;\n  color: #2e7d32;\n  padding: 2px 8px;\n  border-radius: 4px;\n}\n\n.number-highlight-list {\n  background-color: #2e7d32;\n  color: white;\n  padding: 4px 8px;\n  border-radius: 4px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
