import React from "react";
import { ApplyNew } from "../../components/application";
import AppClass from "../../AppClass";
import axios from "axios";
import Axios from "axios";
import moment from "moment";
import toastMessage from "../../components/toastMessage";
import {
  getLoanAmortization,
  getTotalInterest,
  sumFees,
} from "../../utils/loan_amortization";
import HOST_URL from "../../configs/api";
import { Loading } from "../../components/Loader";
class ApplyLoan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: 3,
      response: {},
      payment_duration: "",
      monthly_payment: 0,
      total_interest: 0,
      total_payments: 0,
      monthly_interest: 0,
      disabled: true,
      application_id: "",
      sacco: "",
      user_id: "",
      isLoading: false,
      apps: [],
      term: "Monthly",
      available: true,
      sacco_name: "",
      interestrate: "",
      saved_loan: {},
      results: [],
      contribution_amount: 0,
      short_term_purpose: [],
      long_term_purpose: [],
      loan_type: [],
      setting: {},
      fleet: [],
      fleets: [],
      count_fleet: 1,
      grace_period: 0,
      number_tyres: 0,
      loan_products: [],
      payment_products: [],
      payment_product_id: null,
      purpose: {},
      purpose_value: "",
      start_date_loan: moment().format("YYYY-MM-DD"),
      loan_period: "",
      total_cost_loan: "",
      number_payment: "",
      setting_id: "",
      loan_period_term: "Monthly",
      loan_amount: "",
      maximum_loan_amount: 0,
      free_deposit: 0,
      members: [],
      member: "",
      selected_member: {},
      last_saving: 0,
      need_guarantor: {},
      guarantor: {},
      guarantors: [],
      guaranted_amount: "",
      files: [],
      guarantor_saving: {},
      total_guarantor_guaranted: 0,
      guarantor_amount: [],
      products: [],
      count_loan: 0,
      fees: {},
      totalFees: 0,
      isGettingLoanById: false,
      loanIdData: {},
      product_id: '',
    };

    this.onChangePaymentProduct = this.onChangePaymentProduct.bind(this);
  }

  componentDidMount() {
    const { apps } = this.props;

    let app_options = [];

    for (let el of apps) {
      app_options.push({
        label: el.name,
        value: el._id,
      });
    }

    this.setState(
      {
        apps: app_options,
      },
      () => {
        this.getLoanById();
      }
    );
  }

  getLoanById() {
    if (this.props.somethingID && this.props.somethingID !== "") {
      this.setState({ isGettingLoanById: true });

      const body = {
        tag: "by_id",
        ids: [this.props.somethingID],
        model: "loan",
      };

      AppClass.get_data(body)
        .then((data) => {
          let sacco;
          for (let el of this.state.apps) {
            if (data.data.length > 0 && el.value === data.data[0].app_id) {
              sacco = el;

              this.setState({
                loanIdData: data.data[0],
                isGettingLoanById: false,
                loan_amount: data.data[0].req_amount,
                loan_period: data.data[0].payment_duration,
                fees: data.data[0].fees,
                contribution: data.data[0].contribution,
                start_date_loan: moment(data.data[0].date).format("YYYY-MM-DD"),
              });
            }
          }

          if (sacco) {
            this.onChangeSacco(sacco);
          }
        })
        .catch((err) => {
          console.log("#Error on get loan id", err);
          this.setState({
            isGettingLoanById: false,
          });
        });
    }
  }

  get_loans(app_id) {
    const body = {
      app_id: app_id,
      model: "loan",
      tag: "by_last",
      available: true,
    };

    AppClass.get_data(body)
      .then((data) => {
        this.setState({
          application_id:
            this.state.loanIdData.loan_id ||
            this.generateRandomID(
              this.state?.sacco?.label || "G-" + new Date().getTime(),
              data.data.length + 1
            ),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChangeSacco(e) {
    let sacco = e;

    this.setState(
      {
        sacco,
        last_saving: 0,
        // loan_amount: "",
        loan_products: [],
        fleets: [],
        maximum_loan_amount: 0,
        members: [],
        member: {},
        guarantor: {},
        guarantors: [],
      },
      async () => {
        await this.getProducts(true, sacco.value);
        await this.get_loans([sacco.value]);
        await this.getMembers(true, sacco.value);
        await this.get_guarantors(true, sacco.value);
        await this.get_interest_rate(true, sacco.value);
      }
    );
  }

  getMembers = async (isLoading, app_id) => {
    this.setState({
      isLoading,
    });

    try {
      const members = await AppClass.getRegisteredMembers(
        app_id,
        !this.props.admin ? this.props.user_id : undefined
      );

      for (let el of members) {
        if (this.state.loanIdData.user_id === el.value) {
          this.onChangeMember(el);
        }
      }

      this.setState({ isLoading: false, members });
    } catch (error) {
      toastMessage("error", error);
      this.setState({ isLoading: false });
    }
  };

  get_member_savings(isLoading, app_id, user_id) {
    this.setState({ isLoading, savings: [] });

    const { purpose, product } = this.state;

    let response = {};

    const body = {
      app_id: [app_id],
      model: "save",
      tag: "by_last",
      status: "active",
      user_id,
    };

    if (product && product.value) {
      body["product_id"] = product.value;
    }

    AppClass.get_savedata(body)
      .then((data) => {
        let last_saving = 0,
          product_ids = [];

        if (data.data && data.data.length > 0) {
          for (let el of data.data) {
            last_saving += el.last.total_saving;
            product_ids.push(data.data[0].last.product_id);
          }
        }

        this.setState({
          isLoading: false,
          last_saving,
        });

        let number_times = 0;

        if (purpose.ruleamount) {
          number_times =
            purpose.ruleamount === "3 Times"
              ? 3
              : purpose.ruleamount === "4 Times"
              ? 4
              : 0;
        }

        let maximum_loan_amount = last_saving * number_times;

        const saving_products = this.getSavingProducts(product_ids);

        this.setState({
          maximum_loan_amount,
          products: saving_products,
          product: saving_products.length === 1 ? saving_products : undefined,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  getSavingProducts(product_ids) {
    let { products } = this.state,
      finalProducts = [];

    for (let product of products) {
      if (product_ids.includes(product.value)) {
        finalProducts.push(product);
      }
    }

    if (finalProducts.length === 0) {
      finalProducts = products.slice(0);
    }

    return finalProducts;
  }

  get_guarantor_savings(isLoading, app_id, user_id, index) {
    this.setState({ isLoading, savings: [] });

    let response = {};

    const body = {
      app_id: [app_id],
      model: "save",
      tag: "by_user",
      status: "active",
      user_id,
    };

    AppClass.get_savedata(body)
      .then(async (data) => {
        let { need_guarantor } = this.state;

        if (data.data.length > 0) {
          //get total guaranted
          const total_guaranted_data = await this.get_guaranted_amount(
            data.data[0].user_id
          );

          const total_guaranted =
            total_guaranted_data?.data?.guaranted_amount || 0;

          const free_deposit = data.data[0].total_saving - total_guaranted;

          need_guarantor.guarantor[index]["last_saving"] = {
            _id: data.data[0]._id,
            total_saving: data.data[0].total_saving,
            total_guaranted,
            free_deposit,
          };
        } else {
          // toastMessage(
          //   `${need_guarantor.guarantor[index].name} does not have enough fund`
          // );
          // delete need_guarantor.guarantor[index];
        }

        delete need_guarantor.guarantor[index]["isChecking"];

        this.setState({
          isLoading: false,
          need_guarantor,
        });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  get_guaranted_amount(guarantor_save_id) {
    const body = {
      method: "POST",
      url: `${HOST_URL.url}/get_guaranted_amount`,
      data: {
        model: "loan",
        tag: "by_guarantor_saveid",
        guarantor_save_id: [guarantor_save_id],
      },
    };

    return axios(body);
  }

  getWhereGuarantorHasGuaranted(app_id, guarantor_save_id) {
    this.setState({ isLoading: true });
    let response = {};
    const body = {
      app_id: [app_id],
      tag: "by_last",
      guarantor_save_id,
      model: "loan",
    };
    AppClass.get_data(body)
      .then((data) => {
        // console.log({ total_guarantor_guaranted: data.data });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          isLoading: false,
        });

        toastMessage("error", err);
      });
  }

  get_guarantors = async (isLoading, app_id) => {
    this.setState({
      isLoading,
    });

    try {
      const guarantors = await AppClass.getRegisteredMembers(app_id);

      // let guarantors = [];

      // for (let el of members) {
      //   if (this.state.member._id !== el.user_id) {
      //     guarantors.push({
      //       label: el.user_name,
      //       value: el._id,
      //       ...el,
      //     });
      //   }
      // }

      this.setState({ isLoading: false, guarantors });
    } catch (error) {
      toastMessage("error", error);
      this.setState({ isLoading: false });
    }
  };

  onChangeMember = async (e) => {
    let member = e;

    await this.setState({
      member,
      last_saving: 0,
      last_saving: 0,
      maximum_loan_amount: 0,
    });

    this.get_member_savings(true, this.state.sacco.value, member.value);

    this.getFleets({
      isLoading: true,
      appid: this.state.sacco.value,
      clientid: member.value,
    });
  };

  onChangeAmount(e) {
    let { last_saving, maximum_loan_amount, response } = this.state;
    const loan_amount = e.target.value;

    let need_guarantor = {};

    delete response.guarantor_save_id;
    delete response.amount;

    let diff = 0;

    if (loan_amount > last_saving && loan_amount < maximum_loan_amount) {
      diff = loan_amount - maximum_loan_amount;
      need_guarantor = {
        free_deposit: last_saving,
        guarantor: [
          {
            label: "",
            value: "",
            amount: "",
            error: {},
          },
        ],
      };
    } else {
      need_guarantor = {
        guarantor: [],
      };
    }

    this.setState({
      loan_amount,
      need_guarantor,
      response,
    });
  }

  onChangeTotalPayment(e) {
    this.setState({ total_payments: e.target.value });
  }

  onChangeTotalInterest(e) {
    this.setState({ total_interest: e.target.value });
  }

  onChangeGuarantorAmount(name, i, e) {
    let { need_guarantor } = this.state;

    delete need_guarantor.guarantor[i].error[name];

    need_guarantor.guarantor[i].amount = e.target.value;

    this.setState({ need_guarantor });
  }

  onChangeGuarantor(name, i, e) {
    let { need_guarantor, sacco } = this.state;

    delete need_guarantor.guarantor[i].error[name];

    need_guarantor.guarantor[i].label = e.label;
    need_guarantor.guarantor[i].value = e.value;
    need_guarantor.guarantor[i].isChecking = true;

    this.setState({ need_guarantor }, () => {
      this.get_guarantor_savings(true, sacco.value, e.value, i);
    });
  }

  getFleets({ isLoading, appid, clientid }) {
    this.setState({
      isLoading,
    });

    const config = {
      method: "GET",
      url: HOST_URL.mobitill_url + "/fleets",
      params: {
        ["appid"]: [appid],
        ["clientid"]: [clientid],
      },
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };
    Axios(config)
      .then((data) => {
        const fleets = data.data.data;
        const fleets_array = [];

        for (let i = 0; i < fleets.length; i++) {
          fleets_array.push({
            value: fleets[i].vehicleno,
            label: fleets[i].vehicleno,
          });
        }

        if (this.state?.loanIdData?.fleet?.length > 0) {
          let fleet = [];

          for (let el of this.state.loanIdData.fleet) {
            fleet.push({
              value: el,
              label: el,
            });
          }

          this.setState({ fleet });
        }

        this.setState({
          fleets: fleets_array,
          isLoading: false,
        });
      })
      .catch((error) => {
        toastMessage("error", error?.response?.data?.error || error?.response?.message);

        this.setState({ isLoading: false });
      });
  }

  onAddGuarantor() {
    const { need_guarantor } = this.state;

    let indx = need_guarantor.guarantor.length - 1;

    need_guarantor.guarantor[indx].error = {};

    if (need_guarantor.guarantor[indx].amount === "") {
      need_guarantor.guarantor[indx].error.amount =
        "Guarantor amount is required";
    }

    if (need_guarantor.guarantor[indx].label === "") {
      need_guarantor.guarantor[indx].error.label = "Please select guarantor";
    }

    need_guarantor.guarantor.push({
      label: "",
      value: "",
      amount: "",
      error: {},
    });

    this.setState({
      need_guarantor,
    });
  }

  onRemoveGuarantor(index) {
    const { need_guarantor } = this.state;

    need_guarantor.guarantor.splice(index, 1);

    if (need_guarantor.guarantor.length === 0) {
      need_guarantor.guarantor.push({
        label: "",
        value: "",
        amount: "",
        error: {},
      });
    }

    this.setState({
      need_guarantor,
    });
  }

  randomString(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  generateRandomID(sacco_name, count_loan) {
    this.setState({ isLoading: true });

    AppClass.getLoanId(this.state.sacco.value)
    .then((data) => {
      this.setState({
        application_id: data.data.data.loan_id,
        isLoading: false,
      });
    })
    .catch((error) => {
      this.setState({
        isLoading: false,
      });
      toastMessage("error", error);
    });
  }

  get_interest_rate(isLoading, app_id) {
    this.setState({
      isLoading,
    });

    let response = {};
    const body = {
      app_id,
      model: "setting",
    };
    AppClass.get_setting_data(body)
      .then((_data) => {
        if (_data.data.length > 0) {
          let data = _data.data[0];
          let loan_type = [],
            short_term_purpose = [],
            long_term_purpose = [],
            keys = Object.keys(data);

          if (keys.includes("short_term")) {
            loan_type.push("Short-term Loans");
            short_term_purpose = data.short_term;
          }

          if (keys.includes("long_term")) {
            loan_type.push("Long-term Loans");
            long_term_purpose = data.long_term;
          }

          let fees = {};

          if (!this.state.loanIdData.fees) {
            if (data.fees && typeof data.fees === "string") {
              fees = JSON.stringify(data.fees);
            } else {
              fees = data?.fees || {};
            }
          } else {
            fees = this.state.loanIdData.fees;
          }

          this.setState(
            {
              _id: data._id,
              loan_type,
              available: true,
              long_term_purpose,
              short_term_purpose,
              setting_id: data._id,
              fees,
            },
            () => {
              if (this.state?.loanIdData?._id) {
                this.onChangeLoanType(this.state.loanIdData.loan_type);
              }
            }
          );
        } else {
          this.setState({ available: false });
        }

        this.setState({ isLoading: false });
      })
      .catch((err) => {
        response.error = "Error, " + err;
        this.setState({
          response,
          isLoading: false,
        });
      });
  }

  onChangeLoanType(e) {
    let value = e?.target?.value || e,
      loan_products = [],
      { long_term_purpose, short_term_purpose } = this.state;

    if (long_term_purpose && value === "Long-term Loans") {
      for (let product of long_term_purpose) {
        loan_products.push(product);
      }
    }

    if (long_term_purpose && value === "Short-term Loans") {
      for (let product of short_term_purpose) {
        loan_products.push(product);
      }
    }

    this.setState(
      {
        loan_products,
        loan_type_value: value,
        purpose: {},
        purpose_value: "",
        loan_period: "",
      },
      () => {
        if (this.state?.loanIdData?._id) {
          this.onChangePurpose(this.state.loanIdData.purpose);
        } else if (loan_products.length > 1) {
          this.onChangePurpose(loan_products[0].type);
        }
      }
    );
  }

  onChangePurpose(e) {
    let value = e?.target?.value || e;

    let item = {},
      { loan_products, last_saving } = this.state;

    for (let product of loan_products) {
      if (product.type === value) item = product;
    }

    let number_times = 0;

    if (item.ruleamount) {
      number_times =
        item.ruleamount === "2 Times"
          ? 2
          : item.ruleamount === "3 Times"
          ? 3
          : item.ruleamount === "4 Times"
          ? 4
          : 1;
    }

    let maximum_loan_amount = last_saving * number_times;


    this.setState({
      purpose_value: value,
      purpose: item,
      maximum_loan_amount,
      loan_period: item.loan_period || 0,
      product_id: item._id,
    });
  }

  handleChangeFleet(newValue, actionMeta) {
    this.setState(
      {
        fleet: newValue !== null ? newValue : newValue,
      },
      () => {
        this.setState({
          count_fleet: this.state.fleet ? this.state.fleet.length : 0,
        });
      }
    );
  }

  calculateLoanAmortization() {
    let {
      loan_amount,
      purpose,
      loan_period,
      start_date_loan,
      loan_period_term,
      fees,
    } = this.state;

    let {
      interest_rate,
      term = "Monthly",
      payment = 12,
      contribution = 0,
      method,
    } = purpose;

    const params = {
      loan_amount,
      loan_period,
      start_date_loan,
      interest: interest_rate,
      sacco_term: term,
      sacco_payment_duration: payment,
      client_loan_period_term: loan_period_term,
      contribution,
      method,
      fees,
    };

    let total_interest = getTotalInterest(params);

    params.loan_amount =
      Number(loan_amount) + Number(total_interest) + Number(sumFees(fees));

    let loan_result = getLoanAmortization(params);

    this.setState({
      ...loan_result,
      total_interest,
      totalFees: Number(sumFees(fees)),
    });
  }

  paymentDate(currentDate) {
    var futureMonth = moment(currentDate).add(1, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");

    if (
      // currentDate.date() != futureMonth.date() &&
      futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
    ) {
      futureMonth = futureMonth.add(1, "d");
    }

    return moment(futureMonth).format("YYYY-MM-DD");
  }

  handleFile(e) {
    const files = Array.from(e.target.files);

    this.setState({
      files,
    });
  }

  returnFees(fees) {
    let obj = {},
      _fees = typeof fees === "string" ? JSON.parse(fees) : fees;

    for (let el of Object.keys(_fees)) {
      if (typeof _fees[el] === "boolean") {
        obj[el] = 0;
      } else {
        obj[el] = _fees[el];
      }
    }

    return JSON.stringify(obj);
  }

  handleApplyData() {
    this.setState({ isSubmitting: true });
    const { need_guarantor, purpose, fees, loan_amount } = this.state;

    let c_body = {
      app_id: this.state.sacco.value,
      loan_id: this.state.application_id,
      user_id:
        this.props.role === "admin" && this.state.member.value
          ? this.state.member.value
          : this.props.user_id,
      user_name:
        this.props.role === "admin"
          ? this.state.member.user_name
          : this.props.username,
      loan_type: this.state.loan_type_value,
      loan_calculation_method: purpose.method,
      payment_duration: this.state.number_payment,
    };

    const input_data = {
      ...c_body,
      organization: this.state.sacco.label,
      model: "loan",
      req_amount: loan_amount,
      amount: loan_amount,
      disbursed_amount: this.state.total_cost_loan,
      last_payment: 0,
      interestrate: this.state.setting_id,
      total_interest: this.state.total_interest,
      total_payment: this.state.total_payments,
      phone_number:
        this.props.role === "admin"
          ? this.state.member.phone_number
          : this.props.phone_number,
      purpose: this.state?.purpose?.type || "",
      contribution: this.state.contribution_amount,
      status: "Pending",
      exp_principle_payment: this.state.total_payments,
      expected_interest: this.state.total_interest,
      available: true,
      date: new Date(this.state.start_date_loan).getTime(),
      number_tyres: this.state.number_tyres,
      product_id: this.state.product_id || "",
      payment_product_id: this.state.payment_product_id || "",
    };

    let guaranted_amount = {},
      guarantor_save_id = [];

    if (this.state.need_guarantor && this.state.need_guarantor.guarantor) {
      for (let el of need_guarantor.guarantor) {
        if (
          el.amount &&
          el.amount !== "" &&
          el.value &&
          el.value !== "" &&
          el.label &&
          el.label !== "" &&
          el.last_saving &&
          el.last_saving.free_deposit > 0 &&
          el.label !== "" &&
          el.last_saving &&
          el.last_saving.total_saving > 0
        ) {
          guaranted_amount[el.value] = {
            amount: el.amount,
            name: el.label,
            last_saving: el.last_saving,
          };
        }

        if (el.last_saving) {
          // guarantor_save_id.push(el.last_saving._id);
        }
      }

      input_data.guaranted_amount = JSON.stringify(guaranted_amount);
    }

    if (input_data.product_id === "") {
      delete input_data.product_id;
    }

    // if (guarantor_save_id.length > 0) {
    input_data.guarantor_save_id = JSON.stringify(
      Object.keys(guaranted_amount)
    );
    // }

    if (Object.keys(fees).length !== 0) {
      input_data.fees = this.returnFees(fees);
    }

    const errors = AppClass.validate_form(input_data);

    // format values to 2 DP
    input_data.disbursed_amount = parseFloat(input_data.disbursed_amount).toFixed(2);
    input_data.total_interest = parseFloat(input_data.total_interest).toFixed(2);
    input_data.expected_interest = parseFloat(input_data.expected_interest).toFixed(2);

    for (var i = 0; i < this.state.files.length; i++) {
      input_data["file" + i] = this.state.files[i];
    }

    if (this.state.fleet.value) {
      input_data.fleet = this.state.fleet.value;
    }

    if (!Object.keys(errors).length) {
      let response = {};
      console.log('---just before')

      AppClass.add_data(input_data, this.state.loanIdData._id)
        .then(async (data) => {
          console.log('loan application')
          response.success = "Loan Application has been sent, success";

          toastMessage("success", response.success);

          if (!this.state?.loanIdData?._id) {
            await this.onSaveLoanAmortization(c_body);

            this.setState({
              isSubmitting: false,
              sacco_name: "",
              interestrate: "",
              saved_loan: {},
              results: [],
              contribution_amount: 0,
              short_term_purpose: [],
              long_term_purpose: [],
              loan_type: [],
              setting: {},
              fleet: [],
              fleets: [],
              count_fleet: 1,
              grace_period: 0,
              number_tyres: 0,
              loan_products: [],
              purpose: {},
              purpose_value: "",
              start_date_loan: moment().format("YYYY-MM-DD"),
              loan_period: "",
              total_cost_loan: "",
              number_payment: "",
              setting_id: "",
              loan_period_term: "",
              loan_amount: "",
              maximum_loan_amount: 0,
              free_deposit: 0,
              members: [],
              member: "",
              selected_member: {},
              last_saving: 0,
              need_guarantor: {},
              guarantor: "",
              guaranted_amount: 0,
              application_id: "",
              payment_product_id: "",
            });
          }

          if (this.props.role === "admin") {
            window.location.href = `/home/admin/view_loan_application/${data.data._id}`;
          }
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
          response.error = "Error, " + err;
          this.setState({
            response,
          });

          toastMessage("error", "Loan application failed");
        });
    } else {
      this.setState({ response: errors, isSubmitting: false });
    }
  }

  saveGuarantorInfo(loan) {
    let response = {};

    this.setState({
      isSubmitting: false,
    });

    response.success = "Loan Application has been sent, success";

    toastMessage("success", response.success);

    window.location.reload();
  }

  onChangeSavingProduct(e) {
    this.setState(
      {
        product: e,
      },
      () => {
        this.get_member_savings(
          true,
          this.state.sacco.value,
          this.state.member.value
        );
      }
    );
  }

  getProducts = async (isLoading, app_id) => {
    this.setState({
      isLoading,
    });

    const options = {
      method: "GET",
      url: `${HOST_URL.mobitill_url}/products?0[]=appid&1[]=${app_id}`,
      headers: {
        Authorization: "Bearer " + this.props.ztoken,
      },
    };

    return await Axios(options)
      .then((data) => {
        const products = [];
        const payment_products = []
        for (let el of data.data.data) {
          if (el.loaning) {
            products.push({
              label: el.name,
              value: el._id,
            });

            if (el.loaning_loans) {
              payment_products.push({
                label: el.name,
                value: el._id,
              })
            }

            if (this.state.loanIdData.product_id === el._id) {
              this.onChangeSavingProduct({
                label: el.name,
                value: el._id,
              });
            }
          }
        }
        this.setState({ isLoading: false, products, payment_products });
      })
      .catch((error) => {
        this.setState({ isLoading: false });

        toastMessage("error", error);
      });
  };

  onSaveLoanAmortization = async (data) => {
    try {
      const options = {
        method: "POST",
        url: HOST_URL.url + "/add_amortization",
        data: {
          ...data,
          amortization_schedule: this.state.results,
          status: "Active",
        },
      };

      return await axios(options);
    } catch (error) {
      toastMessage("error", "Failed to add loan amortization schedule");
    }
  };

  onChangeFees(field, e) {
    let { fees } = this.state;

    fees[field] = e.target.value;
    this.setState({ fees });
  }

  onChangePaymentProduct = (e) => {
    const value = e.target.value;
    this.setState({ payment_product_id: value });
  }

  render() {
    const {
      response,
      sacco_name,
      apps,
      available,
      interestrate,
      saved_loan,
      purpose,
      payment_duration,
      method,
      contribution_rate,
      contribution_amount,
      short_term_purpose,
      long_term_purpose,
      loan_type,
      loan_type_value,
      fixed_amount,
      fleet,
      number_tyres,
      fleets,
    } = this.state;

    return (
      <div
        className="content-cont"
        style={{ height: "100%", overflowY: "auto" }}
      >
        {this.state.isGettingLoanById && (
          <Loading icon="fa fa-spinner fa-spin" />
        )}
        <ApplyNew
          payment_duration={payment_duration}
          response={response}
          rate={this.state.rate}
          handleformonchange={(e) => AppClass.handleformonchange(this, e)}
          calculateLoanAmortization={this.calculateLoanAmortization.bind(this)}
          monthly_interest={this.state.monthly_interest}
          monthly_payment={this.state.monthly_payment}
          total_payments={this.state.total_payments}
          total_interest={this.state.total_interest}
          disabled={this.state.disabled}
          onChangeSacco={this.onChangeSacco.bind(this)}
          application_id={this.state.application_id}
          sacco={this.state.sacco}
          handleApplyData={this.handleApplyData.bind(this)}
          isSubmitting={this.state.isSubmitting}
          apps={apps}
          interestrate={interestrate}
          available={available}
          sacco_name={sacco_name}
          saved_loan={saved_loan}
          purpose={purpose}
          method={method}
          contribution_rate={contribution_rate}
          contribution_amount={contribution_amount}
          short_term_purpose={short_term_purpose}
          long_term_purpose={long_term_purpose}
          loan_type={loan_type}
          onChangeLoanType={this.onChangeLoanType.bind(this)}
          loan_type_value={loan_type_value}
          onChangePurpose={this.onChangePurpose.bind(this)}
          fixed_amount={fixed_amount}
          fleets={fleets}
          handleChangeFleet={this.handleChangeFleet.bind(this)}
          fleet={fleet}
          number_tyres={number_tyres}
          results={this.state.results}
          loan_products={this.state.loan_products}
          loan_period={this.state.loan_period}
          start_date_loan={this.state.start_date_loan}
          total_cost_loan={this.state.total_cost_loan}
          number_payment={this.state.number_payment}
          loan_period_term={this.state.loan_period_term}
          term_payment={this.state.term_payment}
          term={this.state.term}
          loan_amount={this.state.loan_amount}
          onChangeAmount={this.onChangeAmount.bind(this)}
          maximum_loan_amount={this.state.maximum_loan_amount}
          members={this.state.members}
          member={this.state.member}
          isLoading={this.state.isLoading}
          role={this.props.role}
          onChangeMember={this.onChangeMember.bind(this)}
          need_guarantor={this.state.need_guarantor}
          guaranted_amount={this.state.guaranted_amount}
          guarantor={this.state.guarantor}
          guarantors={this.state.guarantors}
          onChangeGuarantor={this.onChangeGuarantor.bind(this)}
          user_id={this.props.user_id}
          handleFile={this.handleFile.bind(this)}
          last_saving={this.state.last_saving}
          onAddGuarantor={this.onAddGuarantor.bind(this)}
          onChangeGuarantorAmount={this.onChangeGuarantorAmount.bind(this)}
          onRemoveGuarantor={this.onRemoveGuarantor.bind(this)}
          products={this.state.products}
          product={this.state.product}
          onChangeSavingProduct={this.onChangeSavingProduct.bind(this)}
          fees={
            typeof this.state.fees === "string"
              ? JSON.parse(this.state.fees)
              : this.state.fees
          }
          onChangeFees={this.onChangeFees.bind(this)}
          totalFees={this.state.totalFees}
          purpose_value={this.state.purpose_value}
          onChangeTotalPayment={this.onChangeTotalPayment.bind(this)}
          onChangeTotalInterest={this.onChangeTotalInterest.bind(this)}
          payment_products={this.state.payment_products}
          payment_product_id={this.state.payment_product_id}
          onChangePaymentProduct={this.onChangePaymentProduct}
        />
      </div>
    );
  }
}

export default ApplyLoan;
