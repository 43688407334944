import React from "react";
import { Card } from "../../../Card";
import { Select } from "../../../Select";
import AppClass from "../../../../AppClass";
import toastMessage from "../../../toastMessage";
import { Loading } from "../../../Loader";
import { Body, Header, Modal } from "../../../Modal";
import NewReminder from "./NewReminder";
import "./ReminderSettings.css";
import axios from "axios";
import HOST_URL from "../../../../configs/api";

class ReminderSettings extends React.Component {
  state = {
    isLoading: false,
    data: [],
    error: {},
    settingId: "",
  };

  componentDidMount() {
    const { apps } = this.props;
    if (apps.length === 1) {
      this.setState({ appid: apps[0] });
      this.onChangeInput("sacco", apps[0]);
    }
  }

  onChangeInput(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target ? e.target.value : e }, () => {
      if (field === "sacco") {
        this.get_setting_by_appid(e.value);
      }
    });
  }

  get_setting_by_appid(app_id) {
    const body = {
      app_ids: [app_id],
      model: "setting",
      tag: "by_appid",
    };
    this.setState({ isLoading: true });
    AppClass.get_setting_data(body)
      .then((_data) => {
        if (_data.data.length > 0) {
          this.setState({
            data: _data.data[0].loan_reminders || [],
            settingId: _data.data[0]._id,
          });
        } else {
          toastMessage("error", "Loan settings not found");
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        toastMessage("error", "Unable to find loan settings");
        this.setState({
          isLoading: false,
        });
      });
  }

  handleOpenModal(modal) {
    this.setState({ [modal]: true });
  }

  handleCloseModal(modal) {
    this.setState({ [modal]: false });
  }

  handleDeleteReminder = async (reminderToDelete) => {
    this.setState({ isLoading: true });
    const user = await AppClass.get_user_sessions();
    
    // Filter out the reminder to delete
    const updatedReminders = this.state.data.filter(reminder => 
      reminder !== reminderToDelete
    );

    const options = {
      method: "POST",
      url: HOST_URL.url + "/update_settingdata",
      data: {
        id: this.state.settingId, // Make sure you have access to settingId
        loan_reminders: updatedReminders
      },
      headers: {
        Authorization: "Bearer " + user.ztoken,
      },
    };

    try {
      await axios(options);
      
      // Update local state
      this.setState({ 
        data: updatedReminders,
        isLoading: false
      });
      
      toastMessage("success", "Reminder deleted successfully");
    } catch (err) {
      toastMessage("error", "Error deleting reminder, please try again");
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <Card className="col-md-8 col-md-offset-2">
        <div className="panel-heading  card-title">
          <span>Loan Reminders</span>
        </div>
        <div className="panel-body">
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="col-md-12">
                <div className="form-group">
                  <Select
                    label="Sacco"
                    value={this.state.sacco}
                    options={this.props.apps}
                    name="fleet"
                    onChange={(e) => this.onChangeInput("sacco", e)}
                  />
                  {this.state.error.sacco && (
                    <span className="text-danger">
                      {this.state.error.sacco}
                    </span>
                  )}
                </div>
              </div>
              {this.state?.sacco?.value && (
                <div className="col-md-12 table-responsive">
                  <div style={{ marginBottom: "1rem" }}>
                    <button
                      className="btn btn-primary"
                      onClick={this.handleOpenModal.bind(this, "reminderModal")}
                    >
                      New Reminder
                    </button>
                  </div>
                  {this.state.data.length === 0 ? (
                    <div className="text-center p-3">
                      <p className="text-muted">No reminder added yet</p>
                    </div>
                  ) : (
                    <div className="reminder-statements">
                      {this.state.data.map((item, i) => (
                        <div key={i} className="reminder-item">
                          <div className="reminder-text-list">
                            <span className="number-highlight-list">{item.amount}</span>
                            <span className="tag-style-list"> {item.unit} </span>
                            <span className="tag-style-list"> {item.direction} </span>
                            <span className="tag-style-list">
                              {item.event.toString()
                                .replace(/_/g, ' ')
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(' ')}
                            </span>
                          </div>
                          <button 
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => this.handleDeleteReminder(item)}
                          >
                            <i className="fas fa-trash text-danger"></i>
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <Modal show={this.state.reminderModal}>
          <Header>
            <h5 class="modal-title">Create Reminder</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleCloseModal("reminderModal")}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Header>
          <Body>
            <NewReminder
              apps={this.props.apps}
              sacco={this.state.sacco}
              data={this.state.data}
              settingId={this.state.settingId}
              getData={() => this.get_setting_by_appid(this.state.sacco.value)}
              handleCloseModal={() => this.handleCloseModal("reminderModal")}
            />
          </Body>
        </Modal>
      </Card>
    );
  }
}

export default ReminderSettings;
