import React from "react";
import { Select } from "../../../Select";
import AppClass from "../../../../AppClass";
import toastMessage from "../../../toastMessage";
import HOST_URL from "../../../../configs/api";
import axios from "axios";
import "./NewReminder.css";

class NewReminder extends React.Component {
  state = {
    error: {},
    isSubmitting: false,
    isLoading: false,
    amount: "",
    settingId: "",
    event: null,
  };

  onChangeInput(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e.target ? e.target.value : e }, () => {
      if (field === "sacco") {
        this.get_setting_by_appid(e.value);
      }
    });
  }

  get_setting_by_appid(app_id) {
    const body = {
      app_ids: [app_id],
      model: "setting",
      tag: "by_appid",
    };
    this.setState({ isLoading: true });
    AppClass.get_setting_data(body)
      .then((_data) => {
        if (_data.data.length > 0) {
          const { amount, unit, direction } =
            _data.data[0].loan_reminders || {};

          this.setState({
            settingId: _data.data[0]._id,
            amount,
            unit: {
              label: unit,
              value: unit,
            },
            direction: {
              label: direction,
              value: direction,
            },
          });
        } else {
          toastMessage("error", "Loan settings not found");
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch((err) => {
        toastMessage("error", "Unable to find loan settings");
        this.setState({
          isLoading: false,
        });
      });
  }

  validateForm() {
    const { amount, unit, direction, event, error } = this.state;
    const { sacco } = this.props;

    if (amount === "") {
      error.amount = "Amount is required";
    }

    if (!sacco) {
      error.sacco = "Please select a sacco";
    }

    if (!unit) {
      error.unit = "Unit is required";
    }

    if (!direction) {
      error.direction = "Direction is required";
    }

    if (!event) {
      error.event = "Event is required";
    }

    this.setState({ error });
    setTimeout(() => {
      this.setState({ error: {} });
    }, 5000);
  }

  onSubmit = async () => {
    this.validateForm();

    const { amount, unit, direction, event, error } = this.state;
    const { data = [], settingId } = this.props;

    const user = await AppClass.get_user_sessions();
    if (Object.keys(error).length === 0) {
      this.setState({ isSubmitting: true });

      data.push({
        amount,
        unit: unit.value,
        direction: direction.value,
        event: event.value,
      });

      const options = {
        method: "POST",
        url: HOST_URL.url + "/update_settingdata",
        data: {
          id: settingId,
          loan_reminders: data,
        },
        headers: {
          Authorization: "Bearer " + user.ztoken,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            amount: "",
            unit: null,
            direction: null,
            event: null,
          });

          toastMessage("success", "Settings updated successfully");

          this.props.getData();
          this.props.handleCloseModal();
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });

          toastMessage("error", "error updating settings, please try again");
        });
    }
  };

  render() {
    return (
      <div className="">
        <div className="">
          <div className="form-group">
            <Select
              label="Sacco"
              value={this.props.sacco}
              options={this.props.apps}
              name="fleet"
              onChange={(e) => this.onChangeInput("sacco", e)}
            />
            {this.state.error.sacco && (
              <span className="text-danger">{this.state.error.sacco}</span>
            )}
          </div>
        </div>

        {/* Example Reminder */}
        <div className="example-reminder-container">
          <div className="reminder-title">Example Reminder</div>
          <div className="reminder-example-container">
            <span className="reminder-text">Send a reminder</span>
            <span className="number-highlight">2</span>
            <span className="tag-style">Day(s)</span>
            <span className="tag-style">Before</span>
            <span className="tag-style">End of month</span>
          </div>
        </div>

        {/* Reminder Form */}
        <div className="reminder-form-container">
          <span>Send a reminder</span>
          <div 
            style={{
              width: "100px",
              paddingTop: "4px",
            }}
          >
            <input
              type="number"
              className="form-control"
              placeholder="Amount"
              onChange={(e) => this.onChangeInput("amount", e)}
              value={this.state.amount}
            />
            {this.state.error.amount && (
              <span className="text-danger border">{this.state.error.amount}</span>
            )}
          </div>
          <div>
            <Select
              options={[
                {
                  label: "Day(s)",
                  value: "day(s)",
                },
                {
                  label: "Week(s)",
                  value: "week(s)",
                },
                {
                  label: "Month(s)",
                  value: "month(s)",
                },
              ]}
              onChange={(e) => this.onChangeInput("unit", e)}
              value={this.state.unit}
            />
            {this.state.error.unit && (
              <span className="text-danger">{this.state.error.unit}</span>
            )}
          </div>
          <div>
            <Select
              options={[
                {
                  label: "Before",
                  value: "before",
                },
                {
                  label: "After",
                  value: "after",
                },
              ]}
              onChange={(e) => this.onChangeInput("direction", e)}
              value={this.state.direction}
            />
            {this.state.error.direction && (
              <span className="text-danger">{this.state.error.direction}</span>
            )}
          </div>
          <div style={{  }}>
            <Select
              options={[
                {
                  label: "Beginning of month",
                  value: "beginning_of_month",
                },
                {
                  label: "Mid month",
                  value: "mid_month",
                },
                {
                  label: "End of month",
                  value: "end_of_month",
                },
                {
                  label: 'Beginning of year',
                  value: 'beginning_of_year'
                },
                {
                  label: 'Mid year',
                  value: 'mid_year'
                },
                {
                  label: 'End of year',
                  value: 'end_of_year'
                },
                {
                  label: 'Payment Date',
                  value: 'payment_date'
                }
              ]}
              onChange={(e) => this.onChangeInput("event", e)}
              value={this.state.event}
            />
            {this.state.error.event && (
              <span className="text-danger">{this.state.error.event}</span>
            )}
          </div>
        </div>

        {/* reminder preview */}
        <div className="preview-reminder-container">
          <div className="reminder-title">Your Reminder Preview</div>
          <div className="reminder-preview-container">
            <span className="reminder-text">Send a reminder</span>
            <span className="number-highlight-preview">{this.state.amount || '-'}</span>
            <span className="tag-style-preview">{this.state.unit?.label || '-'}</span>
            <span className="tag-style-preview">{this.state.direction?.label || '-'}</span>
            <span className="tag-style-preview">{this.state.event?.label || '-'}</span>
          </div>
        </div>

        {/* Submit Button */}
        <div className="submit-button">
          <button
            type="button"
            className="btn btn-primary request_loan"
            disabled={this.state.isSubmitting}
            onClick={this.onSubmit.bind(this)}
          >
            {this.state.isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    );
  }
}

export default NewReminder;
